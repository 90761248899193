<template>
  <div class="min-h-screen flex flex-col">
    <nav
      class="flex items-center bg-red-900 text-white py-4 px-2 md:px-6"
      aria-label="Global"
    >
      <div class="flex items-center mx-auto">
        <a href="#">
          <span class="sr-only">Easy Radio</span>
          <img
            class="h-8 w-auto"
            src="../assets/easy-radio-white.svg"
            alt="Easy Radio"
          />
        </a>
        <h3 class="text-xl font-bold ml-3 tracking-wide">
          C'est ta radio !
        </h3>
      </div>
    </nav>

    <main>
      <div class="pt-1 -mb-12">
        <div
          class="container xl:w-3/5 text-center mx-auto sm:flex items-center"
        >
          <div class="flex-grow flex-1 py-6 md:pt-0">
            <h2
              class="text-3xl md:text-4xl uppercase tracking-tight font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500"
            >
              Papotons l'émission
            </h2>
            <h2
              class="text-2xl md:text-3xl uppercase tracking-tight font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500"
            >
              avec Tanguy et Aurélien
            </h2>
            <h4
              class="text-2xl md:text-3xl uppercase tracking-tight font-extrabold text-gray-800 mt-2 mb-4"
            >
              Bientôt
            </h4>
            <button
              class="bg-red-700 hover:bg-red-600 text-white font-medium py-2 px-3 m-3 rounded ring ring-red-400 md:hidden focus:outline-none"
              :class="{
                hidden: showForm,
              }"
              @click="showForm = true"
            >
              Inscris-toi pour la prochaine émission
            </button>
            <div
              class="bg-gray-200 shadow rounded py-4 px-4 mx-auto lg:w-3/4 md:block"
              :class="{
                hidden: !showForm,
              }"
            >
              <button
                @click="showForm = false"
                class="float-right font-medium p-1 md:hidden"
              >
                &#x2715;
              </button>
              <h3 class="text-gray-800 text-lg font-medium">
                Inscris-toi pour la prochaine émission
              </h3>
              <form class="block lg:flex" @submit="subscribe">
                <input
                  type="mail"
                  placeholder="exemple@gmail.com"
                  class="py-2 px-3 rounded w-full flex-1 lg:rounded-r-none mt-3"
                  v-model="form.email"
                />
                <button
                  type="submit"
                  class="bg-red-700 hover:bg-red-600 text-white font-medium py-2 px-3 rounded lg:rounded-l-none mt-3"
                >
                  S'inscrire
                </button>
              </form>
            </div>
          </div>
          <div class="flex-shrink w-1/2 mx-auto md:mx-0 md:w-auto">
            <cld-image publicId="easy-radio/tanguy_axocyb.png" class="w-80 mx-auto max-w-full" alt="Tanguy sur Easy Radio">
              <cld-transformation
                effect="improve"
                flags="preserve_transparency"
                quality="auto:good"
                width="500"
                crop="scale"
                fetchFormat="auto"
              />
            </cld-image>
          </div>
        </div>
      </div>
      <div class="container mx-auto -mb-12 px-2">
        <Player class="md:w-2/3 mx-auto z-10 relative" />
      </div>
      <div class="py-24 bg-gray-100">
        <div class="container px-2 mx-auto text-center">
          <h2
            class="text-2xl md:text-3xl uppercase font-bold text-gray-700 tracking-tighter mb-3"
          >
            Rejoins la communauté Easy Radio
          </h2>
          <p class="mb-10">Réagis avec #EasyRadioFr et @EasyRadioFr</p>
          <div class="grid grid-cols-3 mx-auto text-gray-500">
            <a
              href="https://www.facebook.com/EasyRadioFr"
              target="_blank"
              rel="noopener"
              class="hover:text-blue-700"
            >
              <fa :icon="['fab', 'facebook-f']" size="5x" fixed-width />
            </a>
            <a
              href="https://www.instagram.com/easyradiofr"
              target="_blank"
              rel="noopener"
              class="hover:text-pink-600"
            >
              <fa :icon="['fab', 'instagram']" size="5x" fixed-width />
            </a>
            <a
              href="https://twitter.com/easyradiofr"
              target="_blank"
              rel="noopener"
              class="hover:text-blue-400"
            >
              <fa :icon="['fab', 'twitter']" size="5x" fixed-width />
            </a>
          </div>
        </div>
      </div>
    </main>

    <footer
      class="mt-auto bg-gray-900 justify-self-end text-white text-center py-3"
    >
      Tous droits réservés - Easy Radio 2021
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Player from "./Player.vue";
import axios from "axios";

export default defineComponent({
  name: "Home",
  components: {
    Player,
  },
  data() {
    return {
      showForm: false,
      form: {
        email: "",
      },
    };
  },
  mounted() {
    this.$gtag.screenview({
      app_name: "Easy Radio",
      screen_name: "home",
    });
  },
  methods: {
    subscribe(e: Event): void {
      e.preventDefault();
      axios.post(
        "https://1060098e.sibforms.com/serve/MUIEALP-FY0Sgp9tLro7RCkXw_BJ_GcnbFyLeDRjKO9L2mHFqBn9qMpN3T_7yab_qZ8SmA9mp20ZpOOyleOzDr-hOR42w-e5PQkFsfbVOKzEWYs2u_IC1Lg5qwXA-nX5E_DhnpmWhLPxWGEKH42eVvwNSrMGyaT8qp_yJLFE3l3INBxr_chXgv5bBd41d4vP_VZfBE6rS8kTL7lg",
        {
          EMAIL: this.form.email,
        }
      );
    },
  },
});
</script>
