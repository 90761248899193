<template>
  <div class="bg-gray-900 text-white flex items-center shadow-md rounded">
    <div class="p-2 hidden md:block">
      <img :src="albumArt.src" class="h-20 w-20 mx-auto" :alt="albumArt.alt" />
    </div>
    <button
      @click="toogle"
      class="p-2 focus:outline-none md:order-last md:ml-auto"
    >
      <div v-if="isPlaying">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-16 w-16"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-16 w-16"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    </button>
    <div class="p-2">
      <h4 class="font-medium mb-0 text-red-400" v-if="np">
        En direct sur Easy-Radio
      </h4>
      <h4 class="mb-0" v-if="np">
        {{ np.now_playing.song.title }}
        <span class="hidden md:inline"> - </span>
        <br class="md:hidden" />
        {{ np.now_playing.song.artist }}
      </h4>
    </div>
    <audio ref="audio" :src="currentStream" />
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { NowPlaying } from "../entity/NowPlaying";
import { defineComponent, ref } from "vue";

interface Player {
  np?: NowPlaying;
  currentStream: string;
  isPlaying: boolean;
}

export default defineComponent({
  props: {
    radioUrl: {
      type: String,
      default: "https://stream.easy-radio.net/api/nowplaying/radio"
    }
  },
  setup() {
    const audio = ref<HTMLAudioElement | null>(null);

    return {
      audio
    };
  },
  data() {
    return {
      np: undefined,
      currentStream: "",
      isPlaying: false
    } as Player;
  },
  computed: {
    albumArt(): Record<string, string> {
      if (this.np) {
        return {
          src: this.np.now_playing.song.art,
          alt: this.np.now_playing.song.album
        };
      }
      return {
        src: "",
        alt: "Easy Radio"
      };
    }
  },
  mounted() {
    this.checkNowPlaying();
    this.isPlaying = false;
  },
  methods: {
    checkNowPlaying() {
      axios
        .get(this.radioUrl)
        .then(response => {
          const nowPlaying: NowPlaying = response.data;
          this.setNowPlaying(nowPlaying);
          setTimeout(this.checkNowPlaying, 10000);
        })
        .catch(error => {
          console.error(error);
          setTimeout(this.checkNowPlaying, 30000);
        });
    },
    setNowPlaying(nowPlaying: NowPlaying): void {
      this.np = nowPlaying;

      if (this.currentStream === "") {
        this.currentStream = this.np.station.listen_url;
      }

      // eslint-disable-next-line
      const nav: any = navigator;

      if (nav && nav.mediaSession) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        nav.mediaSession.metadata = new MediaMetadata({
          title: this.np.now_playing.song.title,
          artist: this.np.now_playing.song.artist,
          artwork: [{ src: this.np.now_playing.song.art }]
        });
      }
    },
    play(): void {
      this.audio.onerror = e => {
        this.stop();
        console.log(
          "Network interrupted stream. Automatically reconnecting shortly..."
        );
        console.error(e);
        setTimeout(() => {
          this.play();
        }, 2000);
      };

      this.audio.load();
      this.audio.play();
      this.isPlaying = true;

      this.$gtag.event("player_play", {
        event_category: "player",
        event_label: this.np.now_playing.song.id
      });
    },
    stop(): void {
      this.audio.pause();
      this.isPlaying = false;

      this.$gtag.event("player_stop", {
        event_category: "player",
        event_label: this.np.now_playing.song.id
      });
    },
    toogle() {
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
    }
  }
});
</script>
