
import { defineComponent } from "vue";
import Player from "./Player.vue";
import axios from "axios";

export default defineComponent({
  name: "Home",
  components: {
    Player,
  },
  data() {
    return {
      showForm: false,
      form: {
        email: "",
      },
    };
  },
  mounted() {
    this.$gtag.screenview({
      app_name: "Easy Radio",
      screen_name: "home",
    });
  },
  methods: {
    subscribe(e: Event): void {
      e.preventDefault();
      axios.post(
        "https://1060098e.sibforms.com/serve/MUIEALP-FY0Sgp9tLro7RCkXw_BJ_GcnbFyLeDRjKO9L2mHFqBn9qMpN3T_7yab_qZ8SmA9mp20ZpOOyleOzDr-hOR42w-e5PQkFsfbVOKzEWYs2u_IC1Lg5qwXA-nX5E_DhnpmWhLPxWGEKH42eVvwNSrMGyaT8qp_yJLFE3l3INBxr_chXgv5bBd41d4vP_VZfBE6rS8kTL7lg",
        {
          EMAIL: this.form.email,
        }
      );
    },
  },
});
