import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlayCircle,
  faPauseCircle
} from "@fortawesome/free-regular-svg-icons";
import {
  faPaperPlane,
  faPause,
  faPlay
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import Cloudinary, { CldImage, CldTransformation } from "cloudinary-vue";
import VueGtag from "vue-gtag-next";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/performance";

import "./index.css";

library.add(
  faPlayCircle,
  faPauseCircle,
  faPlay,
  faPause,
  faFacebookF,
  faInstagram,
  faTwitter,
  faPaperPlane
);

const firebaseConfig = {
  apiKey: "AIzaSyCvQFmxZ28Db-putxBKc1jEAPatq11YgG0",
  authDomain: "western-bonsai-248712.firebaseapp.com",
  projectId: "western-bonsai-248712",
  storageBucket: "western-bonsai-248712.appspot.com",
  messagingSenderId: "435677264115",
  appId: "1:435677264115:web:8d6d6215ad6901c15b9f86",
  measurementId: "G-0Q2BZX25KZ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const perf = firebase.performance();
const messaging = firebase.messaging();

messaging.getToken({
  vapidKey:
    "BDerHJc-MuxDlC6snkt-W2s1AepNNgVriybbLIJcxQcM_0MS4ofc76JAUPOeecwRoQMOzyYklLsoQOks15GxjKc"
});

const app = createApp(App);

app.component("fa", FontAwesomeIcon);

app.use(VueGtag, {
  property: [{ id: "G-P71XGCWBLM" }]
});

app.use(Cloudinary, {
    configuration: { cloudName: "osparis" },
    components: [CldImage, CldTransformation]
  });

app.mount("#app");
