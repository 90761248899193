
import axios from "axios";
import { NowPlaying } from "../entity/NowPlaying";
import { defineComponent, ref } from "vue";

interface Player {
  np?: NowPlaying;
  currentStream: string;
  isPlaying: boolean;
}

export default defineComponent({
  props: {
    radioUrl: {
      type: String,
      default: "https://stream.easy-radio.net/api/nowplaying/radio"
    }
  },
  setup() {
    const audio = ref<HTMLAudioElement | null>(null);

    return {
      audio
    };
  },
  data() {
    return {
      np: undefined,
      currentStream: "",
      isPlaying: false
    } as Player;
  },
  computed: {
    albumArt(): Record<string, string> {
      if (this.np) {
        return {
          src: this.np.now_playing.song.art,
          alt: this.np.now_playing.song.album
        };
      }
      return {
        src: "",
        alt: "Easy Radio"
      };
    }
  },
  mounted() {
    this.checkNowPlaying();
    this.isPlaying = false;
  },
  methods: {
    checkNowPlaying() {
      axios
        .get(this.radioUrl)
        .then(response => {
          const nowPlaying: NowPlaying = response.data;
          this.setNowPlaying(nowPlaying);
          setTimeout(this.checkNowPlaying, 10000);
        })
        .catch(error => {
          console.error(error);
          setTimeout(this.checkNowPlaying, 30000);
        });
    },
    setNowPlaying(nowPlaying: NowPlaying): void {
      this.np = nowPlaying;

      if (this.currentStream === "") {
        this.currentStream = this.np.station.listen_url;
      }

      // eslint-disable-next-line
      const nav: any = navigator;

      if (nav && nav.mediaSession) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        nav.mediaSession.metadata = new MediaMetadata({
          title: this.np.now_playing.song.title,
          artist: this.np.now_playing.song.artist,
          artwork: [{ src: this.np.now_playing.song.art }]
        });
      }
    },
    play(): void {
      this.audio.onerror = e => {
        this.stop();
        console.log(
          "Network interrupted stream. Automatically reconnecting shortly..."
        );
        console.error(e);
        setTimeout(() => {
          this.play();
        }, 2000);
      };

      this.audio.load();
      this.audio.play();
      this.isPlaying = true;

      this.$gtag.event("player_play", {
        event_category: "player",
        event_label: this.np.now_playing.song.id
      });
    },
    stop(): void {
      this.audio.pause();
      this.isPlaying = false;

      this.$gtag.event("player_stop", {
        event_category: "player",
        event_label: this.np.now_playing.song.id
      });
    },
    toogle() {
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
    }
  }
});
